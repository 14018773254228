import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {

  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.authenticationService.isAuthenticated()
              .pipe(
                tap( isLogged => {
                  if (!isLogged) {
                    this.router.navigate(['./login']);
                  } 
                })
              );
  }
  
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
      return this.authenticationService.isAuthenticated()
              .pipe(
                tap( isLogged => {
                  if ( !isLogged ) {
                    this.router.navigate(['./login']);
                  } 
                })
              );
  }
}
