import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AppComponent } from 'src/app/app.component';
import { ValidatorService } from 'src/app/shared/validators/validator.service';
import { AppState } from 'src/app/store/app.reducers';
import { AuthService } from '../services/auth.service';

import * as actions from '../../store/actions';
import { FirebaseAuthenticationErrors } from '../../shared/models/FirebaseAuthenticationErrors.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup = this.fb.group({
    email: [ '', [ Validators.required,  Validators.pattern( this.validatorService.emailPattern )] ],
    password: [ '', [ Validators.required ] ],
  }); 

  uiSubscription!: Subscription;
  loading: boolean = false;

  errorMessage: string = null;
  
  constructor(
    public app: AppComponent,
    private fb: FormBuilder,
    public validatorService: ValidatorService,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.uiSubscription = this.store.select('ui').subscribe( ui => {
      this.loading = ui.isLoading
    })
  }

  ngOnDestroy(): void {
    this.uiSubscription?.unsubscribe();
  }

  submit() {
    if ( this.loginForm.invalid ) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(actions.isLoading());

    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;

    this.authService.login(email, password)
    .then(credentials => {
      this.store.dispatch(actions.stopLoading());
      this.router.navigate(['/'])
    })
    .catch((err) => {      
      this.errorMessage = FirebaseAuthenticationErrors.parse(err.code);
      this.store.dispatch(actions.stopLoading());

      this.loginForm.reset({
        email: this.loginForm.get('email')?.value
      });
    });
  }

  appleLogin(): void {
    this.authService.appleAuthentication().then(hasLogged => {
      
    });
  }

  googleLogin(): void {
    this.authService.googleAuthentication().then(hasLogged => {
      console.log(hasLogged)
    });
  }

  facebookLogin() {
    this.authService.facebookAuthentication().then(hasLogged => {
      
    });
  }
}
