import { Component } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'accessdenied',
  templateUrl: './access-denied.component.html',
})
export class AccessDeniedComponent {

  constructor(
    public app: AppComponent
  ) {}

}
