import { createReducer, on } from '@ngrx/store';
import { ShortPosition } from '../../stocks/models/short-position.model';

import * as actions from '../actions/shortPosition.actions';

export interface ShortPositionState {
    shortPositions: ShortPosition[],
    loading: boolean,
    loaded: boolean,
    error: any
}

const initialState: ShortPositionState = {
    shortPositions: [],
    error: null,
    loading: true,
    loaded: false
}

const _shortPositionsReducer = createReducer(initialState,
    on(actions.loadShortPositions, state => ({ ...state, loading: true})),

    on(actions.loadShortPositionsSuccess, (state, {shortPositions}) => ({
            ...state,
            error: null,
            loading: false,
            loaded: true,
            shortPositions: [...shortPositions]
        })),

    on(actions.loadShortPositionsError, (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message
        }
    }))
);

export function shortPositionsReducer(state, action) {
    return _shortPositionsReducer(state, action);
}