import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[errorMsg]'
})
export class ErrorMsgDirective implements OnInit {
  htmlElement: ElementRef<HTMLElement>;

  private _color: string = '#FC7272';
  private _text: string = '';


  @Input() set color(value: string) {
    this._color = value;
    this.setColor();
  }

  @Input() set valid(valid: boolean) {
    if ( valid ) {
      
      this.htmlElement.nativeElement.classList.add('hidden');
      this.htmlElement.nativeElement.classList.add('display-none');
    } else {
      
      this.htmlElement.nativeElement.classList.remove('hidden');
      this.htmlElement.nativeElement.classList.remove('display-none');
    }
  }

  @Input() set text(value: string) {
    this._text = value;
    this.setMessage();
  }
  
  constructor( private el: ElementRef<HTMLElement> ) { 
    this.htmlElement = el;
  }

  ngOnInit(): void {
    this.setColor();
    this.setMessage();
  }

  setMessage(): void {
    this.htmlElement.nativeElement.innerHTML = "<span class='p-d-flex p-jc-start p-ai-center p-mt-2'><i class='pi pi-info-circle p-mr-2' style='font-size: 1rem'></i>" + this._text + "</span>";
  }

  setColor(): void {
    this.htmlElement.nativeElement.style.color = this._color;
    this.htmlElement.nativeElement.classList.add('form-text');
  }
}
