import { Injectable } from '@angular/core';

import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../../users';


@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService implements AsyncValidator {

  constructor(
    private userService: UserService
  ) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const promise =  this.userService.getUserByEmail(control.value);
    
    return from(promise)
            .pipe(
              map( user => user ? {emailAlreadyExists : true} : null)
            )
  }
}


