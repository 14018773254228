import { createAction, props } from '@ngrx/store';
import { StockPrice } from '../../stocks/models/stock-price.model';

export const loadWatchlist = createAction(
    '[Watchlist Component] loadWatchlist',
    props<{userUid: string}>()
);

export const loadWatchlistSuccess = createAction(
    '[Watchlist Component] loadWatchlist',
    props<{watchlist: StockPrice[]}>()
);

export const loadWatchlistError = createAction(
    '[Watchlist Component] loadWatchlist',
    props<{payload: any}>()
);


