import { AfterContentInit, Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[color-change]'
})
export class ColorChangeDirective implements AfterContentInit {
  private htmlElement!: ElementRef<HTMLElement>;
  private _dependsOn!: HTMLDivElement;

  @Input() set dependsOn(htmlElement: HTMLDivElement) {
    this._dependsOn = htmlElement;
  }

  constructor(private el: ElementRef) {
    this.htmlElement = el;
  }

  ngAfterContentInit(): void {
    this.setColor();
  }
 
  setColor(): void {
    let value = this._dependsOn ? this._dependsOn.innerText : this.htmlElement.nativeElement.innerText;
    value = value.replace('%', '');
    
    const numericValue: number = +value;

    if (numericValue > 0) { // Green
      this.htmlElement.nativeElement.style.color = '#4CCC6A';
    } else if (numericValue < 0) { // Red
      this.htmlElement.nativeElement.style.color = '#FC7272';
    } else { // Blue
      this.htmlElement.nativeElement.style.color = '#81B6FA';
    }
  }
}
