export class FirebaseStorageErrors {
    static parse(errorCode: string): string {
  
      let message: string;
  
      switch (errorCode) {
        case 'storage/unknown':
            message = 'Ha ocurrido un error desconocido.';
            break;
            
        case 'storage/object-not-found':
            message = 'No existe ningún objeto en la referencia indicada.';
            break;

        case 'storage/bucket-not-found':
            message = 'No se ha configurado ningún bucket para Cloud Storage.';
            break;

        case 'storage/project-not-found':
            message = 'No se ha configurado ningún proyecto para Cloud Storage.';
            break;

        case 'storage/quota-exceeded':
            message = 'Se ha superado la cuota del depósito de Cloud Storage.';
            break;

        case 'storage/unathenticated':
            message = 'el usuario no está autenticado. Vuelve a intentarlo después de realizar la autenticación.';
            break;

        case 'storage/unauthorized':
            message = 'El usuario no tiene autorización para realizar la acción deseada.';
            break;

        case 'storage/retry-limit-exceeded':
            message = 'Se ha superado el límite de tiempo máximo permitido para una operación.';
            break;

        case 'storage/invalid-checksum':
            message = 'El archivo del cliente no coincide con la suma de verificación del archivo que recibió el servidor. Vuelve a subirlo..';
            break;

        case 'storage/canceled':
            message = 'El usuario ha cancelado la operación.';
            break;

        case 'storage/invalid-event-name':
            message = 'Se proporcionó un nombre de evento no válido. El modo debe ser uno de los siguientes: `running`, `progress` o `pause`.';
            break;

        case 'storage/invalid-url':
            message = "Se proporcionó una URL no válida a refFromURL(). Debes usar el formato gs://bucket/object o https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=&ltTOKEN>."
            break;

        case 'storage/invalid-argument':
            message = "El argumento que se pase a put() debe ser un arreglo de tipo `File`, `Blob` o `UInt8`. El argumento que se pase a putString() debe ser una string sin procesar `Base64` o `Base64URL`.";
            break;

        case 'storage/no-default-bucket':
            message = "No se configuró ningún bucket en la propiedad storageBucket.";
            break;

        case 'storage/cannot-slice-blob':
            message = "Ha habido un cambio en el archivo local (se ha borrado, se ha vuelto a guardar, etc.). Intenta volver a subirlo después de verificar que el archivo no haya cambiado.";
            break;

        case 'storage/cannot-slice-blob':
            message = "Ha habido un cambio en el archivo local (se ha borrado, se ha vuelto a guardar, etc.). Intenta volver a subirlo después de verificar que el archivo no haya cambiado.";
            break;

        default:
            message = 'Oops! Algo ha fallado. Inténtalo de nuevo más tarde.';
            break;
      }

      return message;
    }
  }