import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { parse } from 'angular-html-parser';

import firebase from 'firebase/app';
import { from, Observable } from 'rxjs';
import { filter, last, map, mergeMap } from 'rxjs/operators';
import { FirebaseStorageErrors } from 'src/app/shared/models/FirebaseStorageErros.model';
import { ForumPost } from '../models/forum-post.model';


@Injectable({
  providedIn: 'root'
})
export class ForumService {
  private readonly FORUMS_COLLECTION = "forums";
  private readonly POSTS_COLLECTION = "posts";
  private readonly USERS_COLLECTION = "users";

  constructor(
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) { } 




  getPostsByForumUid(forumUid: string): Observable<ForumPost[]> {
    let posts$: Observable<ForumPost[]>;
    
    posts$ = this.firestore.collection<ForumPost>(this.POSTS_COLLECTION, reference => reference.orderBy('date', "desc"))
              .valueChanges()
              .pipe(
                map(posts => posts.filter(post => post.forumUid === forumUid)),
                map(posts => {
                  return posts.map(post => {
                    return new ForumPost(post.message, post.userUid, post.forumUid, post.imageURL, post.date);
                  })
                })
              )

    return posts$;
  }

  postMessage(userUid: string, message: string, forumUid: string): Promise<void> {
    let regularExpression: RegExp = /(data:image\/[^;]+;base64[^"]+)/i;
    let images = message.match(regularExpression)

    message = message.replace(regularExpression, '');

    const forumReference: AngularFirestoreDocument<unknown> = this.firestore.collection(this.FORUMS_COLLECTION).doc(forumUid);
    const userReference: AngularFirestoreDocument<unknown> = this.firestore.collection(this.USERS_COLLECTION).doc(userUid);
    let post: ForumPost = new ForumPost(message, userUid, forumUid);

    return this.firestore.collection(this.POSTS_COLLECTION).add({...post})
      .then((postReference) => {
        return forumReference.update({posts: firebase.firestore.FieldValue.arrayUnion(postReference.id)})
          .then(() => {
            let userUpdate: Promise<void> = userReference.update({posts: firebase.firestore.FieldValue.arrayUnion(postReference.id)});
          
            if (images) {
              return this.savePostImage(postReference.id, images[1]).then(url => {
                post.imageURL = url;
                postReference.update({...post});
              })
            } else {
              return userUpdate;
            }
          })
      })
  }

  private savePostImage(postUid: string, image: string): Promise<any> {    
    const filePath: string = `forumsPostsImages/${postUid}`;
    const fileReference: AngularFireStorageReference = this.storage.ref(filePath);
    const task: AngularFireUploadTask = this.storage.upload(filePath, image);

    fileReference.putString(image, 'data_url', {contentType: 'image/png'})
      .catch((err) => FirebaseStorageErrors.parse(err.message));

    return fileReference.getDownloadURL().toPromise();
  }
}
