import {Component, OnDestroy, OnInit} from '@angular/core';

import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { Store } from '@ngrx/store';

import { filter } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { AppComponent } from 'src/app/app.component';

import { AuthService } from 'src/app/auth/services/auth.service';
import { AppState } from 'src/app/store/app.reducers';
import { MainComponent } from 'src/app/main-component/main.component';
import { User } from 'src/app/users';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html'
})
export class TopBarComponent implements OnInit, OnDestroy{

    breadcrumbSubscription: Subscription;
    userSubscription: Subscription;
    profileName: string;
    profileImage: string;

    user: User;

    items: MenuItem[];

    constructor(public breadcrumbService: BreadcrumbService,
                public app: AppComponent,
                public appMain: MainComponent,
                private authenticationService: AuthService,
                private store: Store<AppState>) {

        this.breadcrumbSubscription = breadcrumbService.itemsHandler$.subscribe(response => {
            this.items = response;
        });
    }

    ngOnInit(): void {
        this.userSubscription = this.store.select('user')
        .pipe(
            filter(({user}) => user != null)
        )
        .subscribe( ({user}) => this.user = user);
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.breadcrumbSubscription?.unsubscribe();
        
    }

    logout(): void {
        this.authenticationService.logout();
    }
}
