import { createAction, props } from '@ngrx/store';
import { ForumPost } from 'src/app/stocks/models/forum-post.model';

export const loadPosts = createAction(
    '[Forum Component] load posts',
    props<{forumUid: string}>()
);

export const loadPostsSuccess = createAction(
    '[Forum Component] load posts success',
    props<{posts: ForumPost[]}>()
    );

export const loadPostsError = createAction(
    '[Forum Component] load posts error',
    props<{payload: any}>()
);
