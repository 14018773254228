import { createReducer, on } from '@ngrx/store';
import { StockPrice } from '../../stocks/models/stock-price.model';

import * as actions from '../actions/watchlist.actions';

export interface WatchlistState {
    watchlist: StockPrice[],
    loading: boolean,
    loaded: boolean,
    error: any
}

const initialState: WatchlistState = {
    watchlist: [],
    error: null,
    loading: true,
    loaded: false
}

const _watchlistReducer = createReducer(initialState,
    on(actions.loadWatchlist, state => ({ ...state, loading: true})),

    on(actions.loadWatchlistSuccess, (state, {watchlist}) => ({
            ...state,
            error: null,
            loading: false,
            loaded: true,
            watchlist: [...watchlist]
        })),

    on(actions.loadWatchlistError, (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message
        }
    }))
);

export function watchlistReducer(state, action) {
    return _watchlistReducer(state, action);
}