import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileImageService {

  constructor(private http: HttpClient) { }

  getProfileImages() {
    return this.http.get<any>('assets/layout/images/avatar/profileImages.json')
      .toPromise()
      .then(response => response.data as any[])
      .then(data => data);
  }
}
