import { createAction, props } from '@ngrx/store';
import { ShortPosition } from '../../stocks/models/short-position.model';

export const loadShortPositions = createAction(
    '[ShortPositions Component] loadShortPositions',
    props<{userUid: string}>()
);

export const loadShortPositionsSuccess = createAction(
    '[ShortPositions Component] loadShortPositionsSucecss',
    props<{shortPositions: ShortPosition[]}>()
);

export const loadShortPositionsError = createAction(
    '[ShortPositions Component] loadShortPositionsError',
    props<{payload: any}>()
);


