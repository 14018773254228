import { ForumPost } from "src/app/stocks/models/forum-post.model";


export class User {

    static fromFirebase({email, displayName, uid, theme, componentsTheme, registrationDate, registrationMethod, profileImage}: any) {
        return new User(uid, email, displayName, theme, componentsTheme, registrationDate, registrationMethod, profileImage);
    }

    public uid?: string;
    public email: string;
    public displayName?: string;
    public photoURL?: string;
    public profileImage?: string;
    public emailVerified: boolean;
    public theme: string;
    public componentsTheme: string;
    public registrationDate: Date;
    public registrationMethod: string;
    public posts: ForumPost[];
    
    constructor(uid: string, email: string, displayName: string, theme?: string, componentsTheme?: string, registrationDate?: Date, registrationMethod?: string, profileImage?: string) {
        this.uid = uid;
        this.email = email;
        this.displayName = displayName;
        this.emailVerified = false;
        this.theme = theme ? theme : 'dim';
        this.componentsTheme = componentsTheme ? componentsTheme : 'purple';
        this.registrationDate = registrationDate ? registrationDate : new Date();
        this.registrationMethod = registrationMethod ? registrationMethod : 'El gurú de la bolsa';
        this.profileImage = profileImage ? profileImage : "assets/layout/images/avatar/profile-image-1.svg";
        this.posts = [];
    }
}