import { Action, createReducer, on } from '@ngrx/store';


import { User } from '../../users';
import * as authActions from './../actions';

export interface AuthState {
    user: User | null; 
}

const initialState: AuthState = {
    user: null,
}

const _authReducer = createReducer(initialState,
    on(authActions.setUser, (state, {user}) => ({ ...state, user: {...user}})),
    on(authActions.unsetUser, state => ({ ...state, user: null})),
);


export function authReducer(state: AuthState | undefined, action: Action) {
    return _authReducer(state, action);
}