import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'variation'
})
export class VariationPipe implements PipeTransform {
  transform(variation: number): string {
    return variation > 0 ? "+" + variation.toString() : variation.toString();
  }
}
