<div class="exception-body access">
    
    <img  [src]="'assets/layout/images/logo-dark'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

    <div class="exception-content">
        <div class="exception-title">ACCESO DENEGADO</div>
        <div class="exception-detail">No tienes los permisos necesarios para acceder a esta página.</div>
        <a [routerLink]="['/']">Volver al Dashboard</a>
    </div>
</div>
