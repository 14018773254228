<div class="exception-body error">
    
    <img  [src]="'assets/layout/images/logo-dark'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

    <div class="exception-content">
        <div class="exception-title">ERROR</div>
        <div class="exception-detail">Algo ha salido mal.</div>
        <a [routerLink]="['/']">Volver al Dashboard</a>
    </div>
</div>
