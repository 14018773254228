import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';

import { forkJoin, Observable } from 'rxjs';
import { last, mergeMap, switchMap } from 'rxjs/operators';
import { FirebaseStorageErrors } from 'src/app/shared/models/FirebaseStorageErros.model';
import { User } from 'src/app/users/models/user.model';





@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly USER_COLLECTION = "users";

  constructor(
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {}  

  public add(user: User): Promise<void | null> {
    let result: Promise<void | null> = new Promise(() => null);
    if (!user || !user?.uid) { return result; }
    
    return this.getUserByUid(user?.uid).then(databaseUser => {
        if (!!databaseUser) { return null }

        return this.firestore.collection(this.USER_COLLECTION).doc(user?.uid)
          .set({...user})
    }).catch(error => {
        console.error(error);
    });
}

public update(user: User): Promise<void> {
    let result: Promise<void> = new Promise(() => null);
    if (!user || !user?.uid) { return result; }

    return this.firestore.collection(this.USER_COLLECTION).doc(user?.uid)
                         .set({...user})
                         .catch(error => console.log(error))
}

public delete(user: User): Promise<any> {
    let result = new Promise(() => null);
    if (!user || !user?.uid) { return result; }

    return this.firestore.collection(this.USER_COLLECTION).doc(user?.uid)
                          .update({active: false, deleteDate: new Date()})
                          .catch(error => console.error(error));
}

  public getUserByUid(uid: string) : Promise<User | null | undefined> {
    return this.firestore.collection(this.USER_COLLECTION).ref.where("uid", "==", uid).limit(1).get().then(userSnapshot => {
        let user = null;
        const userQueryResult = userSnapshot?.docs?.map(userDocument => userDocument.data() as User);

        if (!!userQueryResult) {
            user = userQueryResult.pop();
        }

        return user;
    });
  }

  public getUserByEmail(email: string) : Promise<User | null | undefined> {
    return this.firestore.collection(this.USER_COLLECTION).ref.where("email", "==", email).limit(1).get().then(userSnapshot => {
        let user = null;
        const userQueryResult = userSnapshot?.docs?.map(userDocument => userDocument.data() as User);
        
        if (!!userQueryResult) {
            user = userQueryResult.pop();
        }

        return user;
    });
  }

  public deleteProfileImage(userUid: string) {
    const filePath: string = `profileImages/${userUid}`;
    const fileReference: AngularFireStorageReference = this.storage.ref(filePath);
    
    fileReference.getDownloadURL()
      .toPromise()
      .then(() => fileReference.delete())
      .catch(err => console.log('Delete profile image in storage: ' + FirebaseStorageErrors.parse(err.code)))
  }

  public updateProfileImage(image: File, userUid: string): Observable<[number, any]> {
    const filePath: string = `profileImages/${userUid}`;
    const fileReference: AngularFireStorageReference = this.storage.ref(filePath);

    const task: AngularFireUploadTask = this.storage.upload(`profileImages/${userUid}`, image);

    let progress$: Observable<number> = task.percentageChanges();
    let url$ = task.snapshotChanges().pipe(
      last(),
      mergeMap(() => fileReference.getDownloadURL())
    );

    return forkJoin([progress$, url$]);
  }
}


