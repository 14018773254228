import { createReducer, on } from '@ngrx/store';
import { ForumPost } from 'src/app/stocks/models/forum-post.model';
import * as actions from '../actions/forum.actions';

export interface ForumState {
    posts: ForumPost[]
    loading: boolean,
    loaded: boolean,
    error: any
}

const initialState: ForumState = {
    posts: [],
    error: null,
    loading: true,
    loaded: false
}

const _forumReducer = createReducer(initialState,
    on(actions.loadPosts, state => ({ ...state, loading: true})),

    on(actions.loadPostsSuccess, (state, {posts}) => ({
            ...state,
            error: null,
            loading: false,
            loaded: true,
            posts: [...posts]
        })),

    on(actions.loadPostsError, (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message
        }
    }))
);

export function forumReducer(state, action) {
    return _forumReducer(state, action);
}