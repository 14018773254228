import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


import { ValidatorService } from 'src/app/shared/validators/validator.service';
import { AppState } from 'src/app/store/app.reducers';

import * as actions from '../../store/actions';
import { FirebaseAuthenticationErrors } from '../../shared/models/FirebaseAuthenticationErrors.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styles: [`
    button {
      width: 85%;
      max-width: 310px;
    }
  `
  ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup = this.fb.group({
    password: [ '', [ Validators.required, Validators.pattern( this.validatorService.passwordPattern )] ],
    confirmPassword: [ '', [ Validators.required ] ],
  },
  {
    validators: [
      this.validatorService.fieldsAreEqual('password', 'confirmPassword')
    ]
  }); 

  uiSubscription!: Subscription;
  loading: boolean = false;
  
  passwordResetSuccesfully: boolean = false;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    public validatorService: ValidatorService,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.uiSubscription = this.store.select('ui').subscribe( ui => {
      this.loading = ui.isLoading
    })
  }

  ngOnDestroy(): void {
    this.uiSubscription?.unsubscribe();
  }

  submit() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    const password = this.resetPasswordForm.get('password')?.value;
    const code = this.route.snapshot.queryParams['oobCode'];

    this.store.dispatch(actions.isLoading());
    this.authenticationService.confirmPasswordReset(code, password)
      .then(() => {
        this.store.dispatch(actions.stopLoading());
        this.errorMessage = null;
        this.passwordResetSuccesfully = true;
      })
      .catch(err => {
        this.store.dispatch(actions.stopLoading());
        this.errorMessage = FirebaseAuthenticationErrors.parse(err.code);
        this.passwordResetSuccesfully = false;
      })
  }

}
