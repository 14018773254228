import { createReducer, on } from '@ngrx/store';
import { Dividend } from '../../stocks/models/dividend.model';


import * as actions from '../actions/dividend.actions';

export interface DividendState {
    dividends: Dividend[],
    loading: boolean,
    loaded: boolean,
    error: any
}

const initialState: DividendState = {
    dividends: [],
    error: null,
    loading: true,
    loaded: false
}

const _dividendsReducer = createReducer(initialState,
    on(actions.loadDividends, state => ({ ...state, loading: true})),

    on(actions.loadDividendsSuccess, (state, {dividends}) => ({
            ...state,
            error: null,
            loading: false,
            loaded: true,
            dividends: [...dividends]
        })),

    on(actions.loadDividendsError, (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message
        }
    }))
);

export function dividendsReducer(state, action) {
    return _dividendsReducer(state, action);
}