import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentVariation'
})
export class PercentVariationPipe implements PipeTransform {

  transform(percentVariation: string): string {
    let formattedResult: string = percentVariation;

    if (+percentVariation.replace('%', '') > 0) {
      formattedResult = '+' + percentVariation;
    }

    return formattedResult;
  }
}
