<div class="exception-body notfound">
    
    <img  [src]="'assets/layout/images/logo-dark'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

    <div class="exception-content">
        <div class="exception-title">PÁGINA NO ENCONTRADA</div>
        <div class="exception-detail">La página a la que intentas acceder no existe o no está disponible en estos momentos.</div>
        <a [routerLink]="['/']">Volver al Dashboard</a>
    </div>
</div>
