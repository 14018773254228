import { Injectable, OnDestroy } from '@angular/core';

import firebase from 'firebase/app';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from 'src/app/users/services/user.service';

import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';


import { User } from '../../users';

import { AppState } from 'src/app/store/app.reducers';
import * as actions from '../../store/actions';
import { Store } from '@ngrx/store';
import { Logout } from '../../store/actions';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubscription!: Subscription;
  private _user!: User | null;

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private userService: UserService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  get user() {
    return {...this._user};
  }


  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    
  }

  initializeAuthenticationListener() {
    this.auth.authState.subscribe(firebaseUser => {
      if (firebaseUser) {
        this.userSubscription = this.firestore.doc(`users/${firebaseUser.uid}`).valueChanges()
          .pipe(
            filter(firestoreUser => !!firestoreUser)
          )
          .subscribe(firestoreUser => {
            const user = User.fromFirebase(firestoreUser);
            this._user = user;
            this.store.dispatch(actions.setUser({user}))
          })
      } else {
        this.userSubscription?.unsubscribe();
        this.store.dispatch(actions.unsetUser())
        this._user = null;
      }
    })
  }

  isAuthenticated(): Observable<boolean> {
    return this.auth.authState.pipe(map(firebaseUser => firebaseUser != null));
  }

  appleAuthentication(): Promise<boolean> {
    return this.socialLogin(new firebase.auth.OAuthProvider('apple.com'));
  }
  
  facebookAuthentication(): Promise<boolean>  {
    return this.socialLogin(new firebase.auth.FacebookAuthProvider);
  }

  googleAuthentication(): Promise<boolean> {
    return this.socialLogin(new firebase.auth.GoogleAuthProvider);
  }

  twitterAuthentication(): Promise<boolean> {
    return this.socialLogin(new firebase.auth.TwitterAuthProvider);
  }

  login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signUp(email: string, password: string, displayName: string): Promise<void> {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const uid = user?.uid;
        const newUser: User = new User(uid!, email, displayName, 'dim', 'purple', new Date(), 'El gurú de la bolsa');
        this.userService.add(newUser);

        user.sendEmailVerification();
      })
  }

  confirmEmailVerification(code: string): Promise<void> {
    return this.auth.applyActionCode(code);
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code: string, password: string): Promise<void> {
    return this.auth.confirmPasswordReset(code, password);
  }

  updatePassword(newPassword: string): Promise<void> {
    return firebase.auth().currentUser.updatePassword(newPassword);
  }
  
  logout(): Promise<boolean> {
    return this.auth.signOut().then(() => {
      this._user = null;
      this.store.dispatch(new Logout());
      return this.router.navigate(['/login']);
    })
  }

  socialLogin(provider: any): Promise<boolean> {
    return this.auth.signInWithPopup(provider)
    .then((firebaseUser) => {
      if (!!firebaseUser) {
        return this.router.navigate(['/']);   
      } else {
        return this.logout();
      }
    }).catch((error) => {
      console.log(error);
      return this.logout();
    })
  }
}
