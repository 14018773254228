import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { map, mergeMap, tap, catchError } from "rxjs/operators";

import * as actions from "../actions";






@Injectable()
export class WatchlistEffects {
    constructor(
        private actions$: Actions,
        
    ) {}



    // TODO: 
    /* loadPosts$ = createEffect(
        () => this.actions$.pipe(
            ofType(actions.loadPosts),
            mergeMap(
                (action) => this.forumService.getPostsByForumUid(action.forumUid)
                                .pipe(
                                    tap(posts => console.log(posts)),
                                    map((posts) => actions.loadPostsSuccess({posts})),
                                    catchError(err => of(actions.loadPostsError({payload: err})))
                                ) 
            )           
        )
    );
     */
    
}