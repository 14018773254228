import { Component } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {

  constructor(
    public app: AppComponent
  ) {}

}
