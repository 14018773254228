import {Component, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import {PrimeNGConfig} from 'primeng/api';
import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';


import { AuthService } from './auth/services/auth.service';
import { ProfileImageService } from './shared/services/profile-image.service';
import { AppState } from './store/app.reducers';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menuMode = 'slim';

    colorScheme = '';

    componentThemes: any[] = [
        {name: 'blue', color: '#42A5F5'},
        {name: 'green', color: '#66BB6A'},
        {name: 'lightgreen', color: '#9CCC65'},
        {name: 'purple', color: '#AB47BC'},
        {name: 'indigo', color: '#5C6BC0'},
        {name: 'orange', color: '#FFA726'},
        {name: 'cyan', color: '#26C6DA'},
        {name: 'teal', color: '#26A69A'}
    ];

    profileImages: string[] = [];
    componentsTheme: string;
    menuTheme = 'layout-sidebar-purple';
    inputStyle = 'outlined';
    ripple: boolean = true;
    userSubscription!: Subscription;

    constructor(
        private primengConfig: PrimeNGConfig,
        private authService: AuthService,
        private store: Store<AppState>,
        private profileImagesService: ProfileImageService
    ) {
        this.authService.initializeAuthenticationListener();
    }
    
    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }

    ngOnInit() {
        this.primengConfig.ripple = true;

        this.userSubscription = this.store.select('user')
        .pipe(
            filter(({user}) => user != null)
        )
        .subscribe(({user}) => {
            this.colorScheme = user.theme;
            this.changeColorScheme(user.theme);
        })

        this.profileImagesService.getProfileImages().then(profileImages => {
            profileImages.map(profileImage => this.profileImages.push(profileImage.url))
        });
    }

    changeComponentTheme(theme: string) {
        this.changeStyleSheetsColor('theme-css', theme, 3);
    }

    changeColorScheme(scheme: string) {
        this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
        this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);
    }

    changeStyleSheetsColor(id, value, from) {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {           // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        }
        else if (from === 2) {       // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        }
        else if (from === 3) {       // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }


    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }
}
