import { Pipe, PipeTransform } from '@angular/core';

import { getCode } from "country-list";

@Pipe({
  name: 'countryFlag'
})
export class CountryFlagPipe implements PipeTransform {

  transform(country: string): string {
    const countryCode: string = getCode(country);
    const url: string = "https://www.countryflags.io/" + countryCode + "/flat/24.png"

    return url;
  }
}
