

<h2 *ngIf="!errorMessage">Su email ha sido verificado correctamente.</h2>


<p-message  severity="error"
            [text]="errorMessage"
            class="p-mb-5"
            *ngIf="errorMessage">
</p-message>

<button pButton
        label="Ir al Dashboard"
        type="submit"
        icon="pi pi-angle-left"
        (click)="this.router.navigate(['login'])">
</button>


