import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-email-actions',
  templateUrl: './email-actions.component.html',
  styles: [
  ]
})
export class EmailActionsComponent implements OnInit {
  mode: Params;

  constructor(
    private activatedActivated: ActivatedRoute,
    public app: AppComponent
  ) { }

  ngOnInit(): void {
    this.mode = this.activatedActivated.snapshot.queryParams['mode'];
  }
}
