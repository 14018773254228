
<form [formGroup]="registerForm"
(ngSubmit)="submit()"
(keyup.enter)="submit()">

<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
          
          
            <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

            <div class="login-form">
                <h2>Crea tu cuenta gratuita</h2>
                <p>¿Ya tienes una cuenta? <a [routerLink]="['/login']">Inicia sesión</a></p>

                <p-message  severity="error"
                            [text]="errorMessage"
                            class="p-mb-5"
                            *ngIf="errorMessage">
                </p-message>



                <!-- NOMBRE -->
                <span class="p-float-label">
                    <input id="name"
                       type="text"
                       pInputText
                       name="name"
                       formControlName="name"
                       [class.ng-invalid]="registerForm.get('name')?.invalid && this.registerForm.get('name')?.touched"
                       [class.ng-dirty]="registerForm.get('name')?.invalid && this.registerForm.get('name')?.touched">
                       <label for="name" class="p-ml-4">Nombre</label>
                </span>

            <small  id="name-help"
                    class="p-mb-4 display-none p-mt-2"
                    errorMsg
                    color="#FC7272"
                    [text]="'Falta el nombre.'"
                    [valid]="!this.validatorService.hasError('name', registerForm)"></small>




                 <!-- CÓDIGO DE AUTORIZACIÓN -->
                <span class="p-float-label"        [class.p-mt-5]="!this.validatorService.hasError('name', registerForm)"
                                                   [class.p-mb-4]="!this.validatorService.hasError('authorizationCode', registerForm)"  >
                    <input type="text"
                         pInputText
                         id="authorizationCode"
                         name="authorizationCode"
                         formControlName="authorizationCode"
                         [class.ng-invalid]="registerForm.get('authorizationCode')?.invalid && this.registerForm.get('authorizationCode')?.touched"
                         [class.ng-dirty]="registerForm.get('authorizationCode')?.invalid && this.registerForm.get('authorizationCode')?.touched"> 
                  <label for="authorizationCode" class="p-ml-4">Código de autorización</label>
              </span>

              <small  id="authorizationCode-help"
                      class="p-mb-5 display-none"
                      errorMsg
                      color="#FC7272"
                      [text]="'Falta el código de autorización de registro.'"
                      [valid]="!this.validatorService.hasError('authorizationCode', registerForm)"></small>
                
                <!-- EMAIL -->
                <span class="p-float-label"
                      [class.p-mt-4]="!this.validatorService.hasError('authorizationCode', registerForm)">
                    <input type="text"
                           pInputText
                           id="email"
                           name="email"
                           formControlName="email"
                           [class.ng-invalid]="registerForm.get('email')?.invalid && this.registerForm.get('email')?.touched"
                           [class.ng-dirty]="registerForm.get('email')?.invalid && this.registerForm.get('email')?.touched"> 
                    <label for="email" class="p-ml-4">Correo electrónico</label>
                </span>

                <small  id="email-help"
                        class="p-mb-4 display-none p-mt-2"
                        errorMsg
                        [text]="this.validatorService.getEmailErrorMsg(this.registerForm.get('email').errors)"
                        [valid]="!this.validatorService.hasError('email', registerForm)"></small>


                <!-- PASSWORD -->
                <span class="p-float-label"
                      [class.p-mt-5]="!this.validatorService.hasError('email', registerForm)"
                      [class.p-mb-4]="!this.validatorService.hasError('password', registerForm)">
                    <input  pPassword
                            id="password"
                            [feedback]="false"
                            type="password"
                            name="password"
                            formControlName="password"
                            [class.ng-invalid]="registerForm.get('password')?.invalid && this.registerForm.get('password')?.touched"
                            [class.ng-dirty]="registerForm.get('password')?.invalid && this.registerForm.get('password')?.touched"/>
                    <label for="password" class="p-ml-4">Contraseña</label>
                </span>

                <small  id="password-help"
                        class="p-mb-3 display-none p-mt-2"
                        errorMsg
                        [text]="this.validatorService.getPasswordErrorMsg(this.registerForm.get('password').errors)"
                        [valid]="!this.validatorService.hasError('password', registerForm)">
                </small>

                <password-requisites [form]="registerForm"></password-requisites>

                <button pButton
                        label="Continuar"
                        class="p-mt-4"
                        type="submit"
                        *ngIf="!loading"
                        [disabled]="registerForm.invalid || registerForm.pending">
                </button>

               
                <button pButton
                        label="Espere por favor..."
                        class="p-mt-4"
                        type="submit"
                        *ngIf="loading"
                        disabled>
                        <i class="pi pi-spin pi-spinner"></i>
                </button>

                <span class="p-mt-3">O regístrate con:</span>
                        <div class="icons p-mt-3">
                            <i class="pi pi-twitter" id="twitter-logo"></i>
                            <img  [src]="'assets/layout/images/google-logo.svg'" id="google-logo"/>
                            <i class="pi pi-facebook" id="facebook-logo"></i>
                            <i class="pi pi-apple" id="apple-logo"></i>
                        </div>
            </div>

            <p></p>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Crea una cuenta</h1>
                <h1>para acceder</h1>
                <h1>a más información</h1>
                <h3>Creando tu cuenta podrás acceder 
                    <br/> a toda la información sobre los valores
                    <br/> que más te interesan y asi tomar mejores
                    <br/>decisiones de inversión.</h3>
            </div>
            <div class="image-footer">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div class="icons">
                    <i class="pi pi-github"></i>
                    <i class="pi pi-twitter"></i>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
