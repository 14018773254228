import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { map, mergeMap, tap, catchError } from "rxjs/operators";

import * as actions from "../actions";

import { of } from 'rxjs';
import { ForumService } from "src/app/stocks/services/forum.service";




@Injectable()
export class ForumEffects {
    constructor(
        private actions$: Actions,
        private forumService: ForumService
    ) {}
    
    loadPosts$ = createEffect(
        () => this.actions$.pipe(
            ofType(actions.loadPosts),
            mergeMap(
                (action) => this.forumService.getPostsByForumUid(action.forumUid)
                                .pipe(
                                    map((posts) => actions.loadPostsSuccess({posts})),
                                    catchError(err => of(actions.loadPostsError({payload: err})))
                                ) 
            )           
        )
    );
}