import { ActionReducer } from "@ngrx/store";
import { ActionTypes } from "../actions";


export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
  
      if (action.type === ActionTypes.LOGOUT) {
        state = undefined;
      }
  
      return reducer(state, action);
    };
}