import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockVolume'
})
export class StockVolumePipe implements PipeTransform {

  transform(volume: number): string {
    let transformedVolume: string;
    const numberOfDigits = volume.toString().length;

    switch(numberOfDigits) {
      case 1:
      case 2:
      case 3:
        transformedVolume = '' + volume;
      break;
      
      case 4:
      case 5:
        transformedVolume = '' + (volume / 1e3).toFixed(2) + ' K'
      break;

      case 6:
      case 7:
      case 8:
        transformedVolume = '' + (volume / 1e6).toFixed(2) + ' M'
      break;

      case 9:
        transformedVolume = '' + (volume / 1e9).toFixed(2) + ' B'
      break;
    }

    return transformedVolume;
  }

}
