import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ErrorComponent } from './error/error.component';
import { ErrorMsgDirective } from './directives/error-msg.directive';
import { FooterComponent } from './footer/footer.component';
import { RightmenuComponent } from './rightmenu/rightmenu.component';
import { MenuComponent } from './menu/menu.component';


import { TopBarComponent } from './topbar/topbar.component';

import { SearchComponent } from './components/search/search.component';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { ConfigComponent } from './config/config.component';
import { MenuitemsComponent } from './menuitems/menuitems.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordRequisitesComponent } from './components/password-requisites/password-requisites.component';
import { ColorChangeDirective } from './directives/color-change.directive';
import { StockVolumePipe } from './pipes/stock-volume.pipe';
import { CountryFlagPipe } from './pipes/country-flag.pipe';
import { VariationPipe } from './pipes/variation.pipe';
import { PercentVariationPipe } from './pipes/percent-variation.pipe';






@NgModule({
  declarations: [
    AccessDeniedComponent,
    ConfigComponent,
    ErrorComponent,
    FooterComponent,
    MenuComponent,
    MenuitemsComponent,
    PageNotFoundComponent,
    RightmenuComponent,
    SearchComponent,
    TopBarComponent,
    PageNotFoundComponent,
    PasswordRequisitesComponent,

    ColorChangeDirective,
    ErrorMsgDirective,

    CountryFlagPipe,
    PercentVariationPipe,
    StockVolumePipe,
    VariationPipe,
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    RouterModule
  ],
  exports: [
    AccessDeniedComponent,
    
    ConfigComponent,
    ErrorComponent,
    
    FooterComponent,
    MenuComponent,
    MenuitemsComponent,
    PageNotFoundComponent,
    PasswordRequisitesComponent,
    RightmenuComponent,
    SearchComponent,
    TopBarComponent,

    ColorChangeDirective,
    ErrorMsgDirective,

    CountryFlagPipe,
    PercentVariationPipe,
    StockVolumePipe,
    VariationPipe,
  ]
})
export class SharedModule { }
