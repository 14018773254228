import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';

import { ValidatorService } from 'src/app/shared/validators/validator.service';
import { AppState } from 'src/app/store/app.reducers';
import * as actions from '../../store/actions';
import { FirebaseAuthenticationErrors } from '../../shared/models/FirebaseAuthenticationErrors.model';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [
  ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup = this.fb.group({
    email: [ '', [ Validators.required,  Validators.pattern( this.validatorService.emailPattern )] ]
  }); 

  uiSubscription!: Subscription;
  loading: boolean = false;
  errorMessage: string;
  emailSentSuccesfully: boolean = false;

  constructor(
    private fb: FormBuilder,
    public validatorService: ValidatorService,
    private store: Store<AppState>,
    private authService: AuthService,
    public router: Router,
    public app: AppComponent
  ) { }

  ngOnDestroy(): void {
    this.uiSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.uiSubscription = this.store.select('ui').subscribe( ui => {
      this.loading = ui.isLoading
    })
  }


  submit() {
    if ( this.forgotPasswordForm.invalid ) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(actions.isLoading());

    const email = this.forgotPasswordForm.get('email')?.value;
    const password = this.forgotPasswordForm.get('password')?.value;

    this.authService.sendPasswordResetEmail(email)
    .then(() => {
      this.store.dispatch(actions.stopLoading());
      this.errorMessage = null;
      this.emailSentSuccesfully = true;
    })
    .catch((error) => {      
      console.log(error)
      this.errorMessage = FirebaseAuthenticationErrors.parse(error.code)
      this.store.dispatch(actions.stopLoading());
      this.emailSentSuccesfully = false;
      
      this.forgotPasswordForm.reset({
        email: this.forgotPasswordForm.get('email')?.value
      });
    });
  }
}
