import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';


import { AppComponent } from "../../app.component";
import { EmailValidatorService } from "../../shared/validators/email-validator.service";
import { ValidatorService } from "../../shared/validators/validator.service";

import { AppState } from "../../store/app.reducers";
import * as actions from '../../store/actions';

import { AuthService } from '../services/auth.service';

import { FirebaseAuthenticationErrors } from '../../shared/models/FirebaseAuthenticationErrors.model';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup = this.formBuilder.group({
    name: [ '', [ Validators.required ] ],
    authorizationCode: ['', [ Validators.required ]],
    email: [ '', [ Validators.required,  Validators.pattern( this.validatorService.emailPattern )], [ this.emailValidator ] ],
    password: [ '', [ Validators.required, Validators.pattern( this.validatorService.passwordPattern ) ] ],
  }); 

  loading: boolean = false;
  uiSubscription!: Subscription;
  authorizationCode: string = "fnojgoitg/“[x@mdepmr5].,poimf75siunxzx43S";
  
  errorMessage: string = null;

  constructor(
    public app: AppComponent,
    private formBuilder: FormBuilder,
    public validatorService: ValidatorService,
    private emailValidator: EmailValidatorService,
    private store: Store<AppState>,
    private authenticationService: AuthService,
    private router: Router
  ) { }
  
  ngOnInit(): void {
    this.uiSubscription = this.store.select('ui').subscribe( ui => {
      this.loading = ui.isLoading
    }) 
  }

  ngOnDestroy(): void {
    this.uiSubscription?.unsubscribe();
  }

  submit() {
    if ( this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    } else if (this.authorizationCode != this.registerForm.get('authorizationCode')?.value) {
      this.errorMessage = "El código de autorización no es correcto."
      this.registerForm.markAllAsTouched();
      return;
    }

    this.store.dispatch(actions.isLoading());
    this.register();

    this.registerForm.reset({
      name: this.registerForm.get('name')?.value,
      email: this.registerForm.get('email')?.value
    }
    );
  }

  enterPressed() {
    if (this.registerForm.valid) {
      this.submit();
    }
  }

  register(): void {
    const email = this.registerForm.get('email')?.value;
    const password = this.registerForm.get('password')?.value;
    const displayName = this.registerForm.get('name')?.value;
    
    this.authenticationService.signUp(email, password, displayName)
    .then(credentials => {
      this.store.dispatch(actions.stopLoading());
      this.router.navigate(['/'])
    })
    .catch(err => {
      this.store.dispatch(actions.stopLoading());
      this.errorMessage = FirebaseAuthenticationErrors.parse(err.code);
    });
  }

  googleAuthentication() {

  }
}
