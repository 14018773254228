export class FirebaseAuthenticationErrors {
    static parse(errorCode: string): string {
  
      let message: string;
  
      switch (errorCode) {
        case 'auth/wrong-password':
        case 'auth/user-not-found':
            message = 'No existe ningún usuario con esas credenciales.';
            break;

        case 'auth/network-request-failed':
            message = 'Por favor comprueba tu conexión a internet.';
            break;

        case 'auth/too-many-requests':
            message = 'Hemos detectado demasiadas peticiones desde tu dispositivo.';
            break;

        case 'auth/user-disabled':
            message = 'Tu cuenta ha sido deshabilitada o eliminada. Por favor ponte en contaco con el administrador.';
            break;

        case 'auth/requires-recent-login':
            message = 'Por favor inicia sesión e inténtalo de nuevo.';
            break;

        case 'auth/email-already-exists':
            message = 'La dirección de email ya está en uso.';
            break;

        case 'auth/phone-number-already-exists':
            message = 'El número de teléfono ya está en uso.';
            break;

        case 'auth/invalid-phone-number':
            message = 'El número de teléfono no es válido.';
            break;

        case 'auth/invalid-email':
            message = 'La dirección de correo electrónico no es válida.';
            break;

        case 'auth/cannot-delete-own-user-account':
            message = 'No se ha podido eliminar la cuenta.';
            break;

        case 'auth/expired-action-code':
            message = "Se ha superado el tiempo para restablecer la contraseña. Vuelva a solicitarlo para recibir un nuevo enlace."
            break;

        case 'auth/invalid-action-code':
            message = "Este enlace ya no es válido, el email ya había sido confirmado.";
            break;
            
        default:
            message = 'Oops! Algo ha fallado. Inténtalo de nuevo más tarde.';
            break;
      }
  
      return message;
    }
  }