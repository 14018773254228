import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';


import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { EmailActionsComponent } from './auth/email-actions/email-actions.component';
import { ErrorComponent } from './shared/error/error.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';


const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {
        path: 'email/action', 
        component: EmailActionsComponent
    },
    {
        path: '',
        canLoad: [ AuthGuard ],
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./main-component/main-component.module').then( module => module.MainComponentModule )
    },
    {path: 'error', component: ErrorComponent},
    {path: 'access', component: AccessDeniedComponent},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: '**', component: PageNotFoundComponent}
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', useHash: false})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
