
<form   [formGroup]="loginForm"
        (ngSubmit)="submit()"
        (keyup.enter)="submit()">

    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
              

                
                <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

                <div class="login-form">
                    <h2>Inicia sesión en tu cuenta</h2>
                    <p>¿Aún no tienes una cuenta? <a [routerLink]="['/register']">Regístrate</a></p>

                    <p-message  severity="error"
                                [text]="errorMessage"
                                class="p-mb-5"
                                *ngIf="errorMessage">
                    </p-message>


                    <span class="p-float-label">
                        <input type="text"
                               pInputText
                               id="email"
                               name="email"
                               formControlName="email"
                               [class.ng-invalid]="loginForm.get('email')?.invalid && this.loginForm.get('email')?.touched"
                               [class.ng-dirty]="loginForm.get('email')?.invalid && this.loginForm.get('email')?.touched"> 
                        <label for="email" class="p-ml-4">Correo electrónico</label>
                    </span>

                    <small  id="email-help"
                            class="p-mb-4 display-none p-mt-2"
                            errorMsg
                            [text]="this.validatorService.getEmailErrorMsg(this.loginForm.get('email').errors)"
                            [valid]="!this.validatorService.hasError('email', loginForm)"></small>

                    <span class="p-float-label"
                          [class.p-mt-5]="!this.validatorService.hasError('email', loginForm)">
                        <input  pPassword
                                id="password"
                                [feedback]="false"
                                type="password"
                                name="password"
                                formControlName="password"
                                [class.ng-invalid]="loginForm.get('password')?.invalid && this.loginForm.get('password')?.touched"
                                [class.ng-dirty]="loginForm.get('password')?.invalid && this.loginForm.get('password')?.touched"/>
                        <label for="password" class="p-ml-4">Contraseña</label>
                    </span>

                    <small  id="password-help"
                            class="p-mb-3 display-none p-mt-2"
                            errorMsg
                            [text]="'Falta la contraseña'"
                            [valid]="!this.validatorService.hasError('password', loginForm)"></small>

                            <p class="p-mt-1"><a [routerLink]="['/forgot-password']">¿Has olvidado tu contraseña?</a></p>

                            

                    <button pButton
                            label="Continuar"
                            type="submit"
                            *ngIf="!loading">
                    </button>

                    <button pButton
                            label="Espere por favor..."
                            type="submit"
                            *ngIf="loading"
                            disabled>
                            <i class="pi pi-spin pi-spinner"></i>        
                    </button>

                    <span class="p-mt-3">O inicia sesión con:</span>
                        <div class="icons p-mt-3">
                            <i class="pi pi-twitter" id="twitter-logo"></i>
                            <img  [src]="'assets/layout/images/google-logo.svg'" id="google-logo"/>
                            <i class="pi pi-facebook" id="facebook-logo"></i>
                            <i class="pi pi-apple" id="apple-logo"></i>
                        </div>
                </div>

                <p></p>
            </div>
            
            <div class="login-image">
               
            </div>
        </div>
    </div>
</form>

