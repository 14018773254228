import { Action, createReducer, on } from '@ngrx/store';
import * as ui from '../actions/ui.actions';

export interface UIState {
    isLoading: boolean; 
}

const initialState: UIState = {
    isLoading: false,
}

const _uiReducer = createReducer(initialState,
    on(ui.isLoading, state => ({ ...state, isLoading: true})),
    on(ui.stopLoading, state => ({ ...state, isLoading: false}))
);

export function uiReducer(state: UIState | undefined, action: Action) {
    return _uiReducer(state, action);
}