import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'password-requisites',
  templateUrl: './password-requisites.component.html',
  styleUrls: ['./password-requisites.component.scss']
})
export class PasswordRequisitesComponent implements OnInit {
  @Input() form: FormGroup;

  passwordIsSecure: boolean;
  lowercaseInserted: boolean;
  uppercaseInserted: boolean;
  minimum8CharactersInserted: boolean;
  numberInserted: boolean;

  ngOnInit(): void {
    this.form.get('password').valueChanges
      .subscribe(password => {
        this.checkIfPasswordIsSecure(password)
      })
  }

  checkIfPasswordIsSecure(password: string) {
    this.lowercaseInserted = (/[a-z]/.test(password));
    this.uppercaseInserted = (/[A-Z]/.test(password));
    this.numberInserted = (/[0-9]/.test(password));
    this.minimum8CharactersInserted = password.length >= 8;

    this.passwordIsSecure = this.lowercaseInserted &&
                            this.uppercaseInserted &&
                            this.numberInserted    &&
                            this.minimum8CharactersInserted;
  }  
}
