

export class ForumPost {
    date: Date;
    message: string;
    userUid: string;
    imageURL: string;
    forumUid: string;
    uid: string;

    constructor(message: string, userUid: string, forumUid: string, imageURL?: string, date?: Date) {
        this.message = message;
        this.userUid = userUid;
        this.forumUid = forumUid;
        this.imageURL = imageURL ? imageURL : null;
        this.date = date ? date : new Date();
    }
}