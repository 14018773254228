import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { EmailActionsComponent } from './email-actions/email-actions.component';




@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    EmailActionsComponent,
    
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent
  ]
})
export class AuthModule { }
