
<form  [formGroup]="forgotPasswordForm"
       (ngSubmit)="submit()"
       (keyup.enter)="submit()">

    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">

                
                <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

                <div class="login-form">
                    <h2 *ngIf="!emailSentSuccesfully">Contraseña olvidada</h2>
                    <p *ngIf="!emailSentSuccesfully">Por favor introduzca una dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.</p>

                    <h3 *ngIf="emailSentSuccesfully">Las instrucciones para restablecer la contraseña han sido enviadas a su correo.</h3>

                    <p-message  severity="error"
                                [text]="errorMessage"
                                class="p-mb-5"
                                *ngIf="errorMessage">
                    </p-message>

                    <span class="p-float-label" *ngIf="!emailSentSuccesfully">
                        <input type="text"
                            pInputText
                            id="email"
                            name="email"
                            formControlName="email"
                            [class.ng-invalid]="forgotPasswordForm.get('email')?.invalid && this.forgotPasswordForm.get('email')?.touched"
                            [class.ng-dirty]="forgotPasswordForm.get('email')?.invalid && this.forgotPasswordForm.get('email')?.touched"> 
                        <label for="email" class="p-ml-4">Correo electrónico</label>
                    </span>

                    <small  id="email-help"
                            class="p-mb-4 display-none p-mt-2"
                            errorMsg
                            [text]="this.validatorService.getEmailErrorMsg(this.forgotPasswordForm.get('email').errors)"
                            [valid]="!this.validatorService.hasError('email', this.forgotPasswordForm)"></small>

                    <button pButton
                            [class.p-mt-3]="!this.validatorService.hasError('email', this.forgotPasswordForm)"
                            label="Continuar"
                            type="submit"
                            *ngIf="!loading && !emailSentSuccesfully"
                            [disabled]="forgotPasswordForm.invalid || forgotPasswordForm.pending">
                    </button>

                    <button pButton
                            [class.p-mt-3]="!this.validatorService.hasError('email', this.forgotPasswordForm)"
                            label="Espere por favor..."
                            type="submit"
                            *ngIf="loading && !emailSentSuccesfully"
                            disabled>
                            <i class="pi pi-spin pi-spinner"></i>        
                    </button>

                    <button pButton
                            label="Volver a inicio de sesión"
                            type="submit"
                            icon="pi pi-angle-left"
                            (click)="this.router.navigate(['login'])"
                            *ngIf="emailSentSuccesfully">
                    </button>

                    <span class="p-mt-3" *ngIf="!emailSentSuccesfully"><a [routerLink]="['/login']" class="p-mt-3 p-d-flex p-ai-center p-jc-center"><i class="pi pi-angle-left"></i>Volver</a></span>
                    
                        
                </div>

                <p>A problem? <a href="#">Click here</a> and let us help you.</p>
            </div>
            
            <div class="login-image">
            
            </div>
        </div>
    </div>
</form>

