import { createAction, props } from '@ngrx/store';
import { Dividend } from '../../stocks/models/dividend.model';


export const loadDividends = createAction(
    '[Dividends Component] loadDividends',
    props<{userUid: string}>()
);

export const loadDividendsSuccess = createAction(
    '[Dividends Component] loadDividendsSuccess',
    props<{dividends: Dividend[]}>()
);

export const loadDividendsError = createAction(
    '[Dividends Component] loadDividendsError',
    props<{payload: any}>()
);


