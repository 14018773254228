// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stockPriceBaseURL: 'http://api.marketstack.com/v1',
  stockPriceAPIKey: 'f93d25dabf448868102b815fc3e3f6f9',
  shortPositionBaseURL: '',
  shortPositionAPIKey: '',
  dividendBaseURL: '',
  dividendAPIKey: '',
  firebase: {
    apiKey: "AIzaSyCA_pc7ANvFGOOmEHogK_UIY0HyJywwMFw",
    authDomain: "elgurudelabolsa-a2ab5.firebaseapp.com",
    projectId: "elgurudelabolsa-a2ab5",
    storageBucket: "elgurudelabolsa-a2ab5.appspot.com",
    messagingSenderId: "314530540568",
    appId: "1:314530540568:web:f326782a3eef24a704a76d",
    measurementId: "G-8R6Q91N58Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
