import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  public emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  public passwordPattern: string = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}";

  constructor() { }

  fieldsAreEqual(value1: string, value2: string) {
    return ( formGroup: AbstractControl): ValidationErrors | null => {
      const field1Value = formGroup.get(value1)?.value;
      const field2Value = formGroup.get(value2)?.value;

      if ( field1Value !== field2Value ) {
        formGroup.get(value2)?.setErrors({ notEqual: true });
        return { notEqual: true }
      } 

      formGroup.get(value2)?.setErrors(null);

      return null;
    };
  }

  onylyContainsHtml(value1: string) {
    return ( formGroup: AbstractControl): ValidationErrors | null => {
      const field1Value = formGroup.get(value1)?.value;
    
      return null;
    };
  }

  hasError(field: string, form: FormGroup): boolean {
    return (form.get(field)?.errors && form.get(field)?.touched) || false;
  }

  getEmailErrorMsg(errors: ValidationErrors) {
    if (errors?.required) {
      return "Introduce un email.";
    } else if (errors?.pattern) {
      return "Introduce un email válido.";
    } else if(errors?.emailAlreadyExists) {
      return "El email ya está en uso."
    }

    return "";
  }

  getNameErrorMsg(errors: ValidationErrors) {
    if (errors?.required) {
      return "Introduce un nombre.";
    } else if (errors?.maxlength) {
      return "El nombre es demasiado largo " + errors?.maxlength.actualLength + "/" + errors?.maxlength.requiredLength;
    } 

    return "";
  }

  

  getPasswordErrorMsg(errors: ValidationErrors): string {
    if (errors?.required) {
      return "Introduce una contraseña.";
    } else if (errors?.pattern) {
      return "Introduce una contraseña válida.";
    }

    return "";
  }
  
  getConfirmPasswordErrorMsg(errors: ValidationErrors) {
    if (errors?.required) {
      return "Repita la contraseña."
    } else if (errors?.notEqual) {
      return "Las contraseñas no coinciden."
    }

    return "";
  }
}
