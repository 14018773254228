


<div class="login-body">
<div class="login-wrapper">
    <div class="login-panel">
      

        
        <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

        <div class="login-form" [ngSwitch]="mode">
            <reset-password *ngSwitchCase="'resetPassword'"></reset-password>
            <verify-email *ngSwitchCase="'verifyEmail'"></verify-email>
        </div>

        <p>A problem? <a href="#">Click here</a> and let us help you.</p>
    </div>
    
    <div class="login-image">
       
    </div>
</div>
</div>


