import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../users';
import { FirebaseAuthenticationErrors } from '../../shared/models/FirebaseAuthenticationErrors.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
  styles: [
  ]
})
export class VerifyEmailComponent implements OnInit {

  errorMessage: string = null;

  constructor(
    private authenticationService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    public router: Router
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.queryParams['oobCode'];

    this.authenticationService.confirmEmailVerification(code)
      .then(() => {
        // TODO: poner emailVerified a true del usuario
        
      })
      .catch(err => {
        this.errorMessage = FirebaseAuthenticationErrors.parse(err.code);
      });
  }

}
