import { Component } from '@angular/core';
import { AppComponent } from 'src/app/app.component';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  currentYear: number = new Date().getUTCFullYear();
  constructor(public app: AppComponent) {}
}
