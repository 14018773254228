
<form [formGroup]="resetPasswordForm"
      (ngSubmit)="submit()"
      (keyup.enter)="submit()"
      class="login-form">
    <h2 >Restablecer contraseña</h2>
    <h4 *ngIf="passwordResetSuccesfully">Se ha restablecido correctamente la contraseña.</h4>

    <p-message  severity="error"
                [text]="errorMessage"
                class="p-mb-5"
                *ngIf="errorMessage">
    </p-message>


    <span class="p-float-label p-mt-2 p-mb-1"
          *ngIf="!(passwordResetSuccesfully || errorMessage)">
        <input  pPassword
            id="password"
            [feedback]="false"
            type="password"
            name="password"
            formControlName="password"
            [class.ng-invalid]="resetPasswordForm.get('password')?.invalid && this.resetPasswordForm.get('password')?.touched"
            [class.ng-dirty]="resetPasswordForm.get('password')?.invalid && this.resetPasswordForm.get('password')?.touched"/>
        <label for="password" class="p-ml-4">Contraseña</label>
    </span>

    <small  id="password-help"
            class="display-none"
            errorMsg
            [text]="this.validatorService.getPasswordErrorMsg(this.resetPasswordForm.get('password').errors)"
            [valid]="!this.validatorService.hasError('password', resetPasswordForm)"></small>

    <span class="p-float-label p-mt-5 p-mb-1" 
          *ngIf="!(passwordResetSuccesfully || errorMessage)"
          [class.p-mb-3]="!this.validatorService.hasError('confirmPassword', resetPasswordForm)">
        <input  pPassword
            id="confirmPassword"
            [feedback]="false"
            type="password"
            name="confirmPassword"
            formControlName="confirmPassword"
            [class.ng-invalid]="resetPasswordForm.get('confirmPassword')?.invalid && this.resetPasswordForm.get('confirmPassword')?.touched"
            [class.ng-dirty]="resetPasswordForm.get('confirmPassword')?.invalid && this.resetPasswordForm.get('confirmPassword')?.touched"/>
        <label for="password" class="p-ml-4">Confirmar contraseña</label>
    </span>

    <small  id="confirm-password-help"
            class="display-none p-mb-2"
            errorMsg
            [text]="this.validatorService.getConfirmPasswordErrorMsg(this.resetPasswordForm.get('confirmPassword').errors)"
            [valid]="!this.validatorService.hasError('confirmPassword', resetPasswordForm)">
    </small>

    <password-requisites [form]="resetPasswordForm"
                         *ngIf="!(passwordResetSuccesfully || errorMessage)">
    </password-requisites>


    <button pButton
            class="p-mt-3"
            label="Continuar"
            type="submit"
            *ngIf="!loading && !(passwordResetSuccesfully || errorMessage)">
    </button>

    <button pButton
            class="p-mt-3"            
            label="Espere por favor..."
            type="submit"
            *ngIf="loading && !(passwordResetSuccesfully || errorMessage)"
            disabled>
            <i class="pi pi-spin pi-spinner"></i>        
    </button>


    <button pButton
            label="Volver a inicio de sesión"
            type="submit"
            icon="pi pi-angle-left"
            (click)="this.router.navigate(['login'])"
            *ngIf="passwordResetSuccesfully || errorMessage">
    </button>

    

</form>