

<p-message  severity="success"
            text="La contraseña introducida es segura."
            *ngIf="passwordIsSecure">
</p-message>

<div class="p-d-flex p-jc-start p-ai-center p-mb-3 p-mt-2"
     id="password-requisites"
     *ngIf="!passwordIsSecure">

    <div class="p-mr-3">
        <div class="p-d-flex p-jc-start p-ai-center"><i class="pi register-password-icon p-mr-1" [ngClass]="lowercaseInserted ? 'pi-check-circle' : 'pi-times-circle'"></i> <p>Una minúscula</p></div>
        <div class="p-d-flex p-jc-start p-ai-center"><i class="pi register-password-icon p-mr-1" [ngClass]="uppercaseInserted ? 'pi-check-circle' : 'pi-times-circle'"></i> <p>Una mayúscula</p></div>
    </div>
    
    <div>
        <div class="p-d-flex p-jc-start p-ai-center"><i class="pi register-password-icon p-mr-1" [ngClass]="numberInserted ? 'pi-check-circle' : 'pi-times-circle'"></i> <p>Un número</p></div>
        <div class="p-d-flex p-jc-start p-ai-center"><i class="pi register-password-icon p-mr-1" [ngClass]="minimum8CharactersInserted ? 'pi-check-circle' : 'pi-times-circle'"></i> <p>Mínimo 8 caracteres</p></div>
    </div>
</div>