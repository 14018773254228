import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';


export interface AppState {
    ui: reducers.UIState,
    user: reducers.AuthState,
    forum: reducers.ForumState,
    watchlist: reducers.WatchlistState,
    shortPositions: reducers.ShortPositionState,
    dividends: reducers.DividendState
}

export const appReducers: ActionReducerMap<AppState> = {
    ui: reducers.uiReducer,
    user: reducers.authReducer,
    forum: reducers.forumReducer,
    watchlist: reducers.watchlistReducer,
    shortPositions: reducers.shortPositionsReducer,
    dividends: reducers.dividendsReducer
}